
const displayBlocks = () => {
  const blocks = document.querySelectorAll('.block-checkbox');
  const inputs = document.querySelectorAll('.block-input');

  if (blocks) {
    blocks.forEach(block => {
      block.addEventListener('click', event => {
        if (!event.currentTarget.checked) {
          inputs.forEach(input => {
            if (input.dataset.block == event.currentTarget.value) {
              input.parentNode.lastElementChild.disabled = true
              input.parentNode.classList.add('d-none')
            }
          })
        } else {
          inputs.forEach(input => {
            if (input.dataset.block == event.currentTarget.value) {
              input.parentNode.lastElementChild.disabled = false
              input.parentNode.classList.remove('d-none')
            }
          })
        }
      })
    })
  }
}

export { displayBlocks };
