const enableEndDate = () => {
  const dates = document.querySelectorAll('.entreprise_users_end_date');

  if (dates.length !== 0) {
    dates.forEach(date => {
      let checkbox = date.parentNode.previousElementSibling.lastElementChild
      if (!checkbox.checked) {
        date.children[1].disabled = true
        date.children[1].value = ""
      }
      checkbox.addEventListener('click', (e) => {
        if (e.currentTarget.checked) {
          date.children[1].disabled = false
        } else {
          date.children[1].disabled = true
          date.children[1].value = ""
        }
      })
    })
  }
}

const enableUserEndDate = () => {
  const date = document.querySelector('#user_end_date');

  if (date) {
    console.dir(date)
    let checkbox = date.parentElement.previousElementSibling.childNodes[0].childNodes[1]
    if (!checkbox.checked) {
      date.disabled = true
      date.value = ""
    }
    checkbox.addEventListener('click', (e) => {
      if (e.currentTarget.checked) {
        date.disabled = false
      } else {
        date.disabled = true
        date.value = ""
      }
    })
  }
}

export { enableEndDate, enableUserEndDate }
