const initSnackBar = () => {
  window.addEventListener('beforeinstallprompt', function(event) {
    // not show the default browser install app prompt
    event.preventDefault();

    // add the banner here or make it visible
    // …
    var snackbar = document.getElementById("snackbar");

    if (snackbar) {
      // Add the "show" class to DIV
      snackbar.className = "show";


      // After 3 seconds, remove the show class from DIV
      setTimeout(function(){ snackbar.className = snackbar.className.replace("show", ""); }, 10000);
      // save the event to use it later
      // (it has the important prompt method and userChoice property)
      window.promptEvent = event;
    }
  });

  document.addEventListener('click', function(event) {
    if (event.target.matches('.install-app')) {
      addToHomeScreen();
    }
  });

  function addToHomeScreen() {
    // show the install app prompt
    window.promptEvent.prompt();

    // handle the Decline/Accept choice of the user
    window.promptEvent.userChoice.then(function(choiceResult) {
      // hide the prompt banner here

      if (choiceResult.outcome === 'accepted') {
        console.info('mm User accepted the A2HS prompt');
      } else {
        console.info('mm User dismissed the A2HS prompt');
      }

      window.promptEvent = null;
    });
  }
}

export { initSnackBar };
