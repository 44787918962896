const checkSiret = () => {
  const button = document.querySelector('#check-siret');
  const formButton = document.querySelector('#form-button');

  if (button) {
    formButton.disabled = true
    formButton.classList.add('disabled')
    formButton.insertAdjacentHTML('afterend',
            `<div class="error-message-button" style='color: red;'>Vous devez avoir vérifié votre SIRET avec succès pour valider le formulaire.</div>`)

    button.addEventListener('click', event => {
      const siret = event.currentTarget.previousElementSibling.childNodes[1].value

      const isValid = (siret) => {
        const strippedSiret = siret.replace(/\s/g, '')
        if (strippedSiret.length === 14) {
          return strippedSiret
        } else {
          return false
        }
      }

      if (isValid(siret)) {
        const strippedSiret = siret.replace(/\s/g, '')
        fetch(`https://api.insee.fr/entreprises/sirene/V3/siret/${strippedSiret}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${event.currentTarget.dataset.token}`
          }
        })
        .then(response => response.json())
        .then(data => {
          const previousError = document.querySelector('.error-message-insee')

          if (previousError) {
            previousError.remove()
          }

          if (data.header.statut == 200) {
            const buttonError = document.querySelector('.error-message-button')
            const formError = document.querySelector('.error-message-insee');
            if (buttonError) {
              buttonError.remove();
            }
            if (formError) {
              formError.remove();
            }
            button.insertAdjacentHTML('afterend',
              `<div class="error-message-insee mb-3" style='color: green;'>
                Félicitations, nous avons vérifié l'existence de votre société.
                </br>
                Vous pouvez poursuivre la création.
              </div>`)
            formButton.disabled = false
            formButton.classList.remove('disabled');

            const nameInput = document.querySelector("#entreprise_name");
            // const dataName = data.etablissement.uniteLegale.denominationUniteLegale
            // if (dataName) {
            //   nameInput.value = dataName
            // }
          } else {
            const buttonError = document.querySelector('.error-message-button');
            const formError = document.querySelector('.error-message-insee');
            if (buttonError) {
              buttonError.remove();
            }
            if (formError) {
              formError.remove();
            }
            button.insertAdjacentHTML('afterend',
              `<div class="error-message-insee mb-3" style='color: red;'>${data.header.message} </br> Veuillez contacter léa si ne parvenez pas à valider votre SIRET.</div>`)
            formButton.insertAdjacentHTML('afterend',
              `<div class="error-message-button" style='color: red;'>Vous devez avoir vérifié votre SIRET avec succès pour valider le formulaire.</div>`)
            formButton.disabled = true
            formButton.classList.add('disabled');
          }
        })
      } else {
        const buttonError = document.querySelector('.error-message-button')
        const formError = document.querySelector('.error-message-insee');
        if (buttonError) {
          buttonError.remove();
        }
        if (formError) {
          formError.remove();
        }
        button.insertAdjacentHTML('afterend',
          `<div class="error-message-insee mb-3" style='color: red;'>Le SIRET n'est pas au bon format (14 chiffres).</div>`)
        formButton.insertAdjacentHTML('afterend',
        `<div class="error-message-button ml-3" style='color: red;'>Vous devez avoir vérifié votre SIRET avec succès pour valider le formulaire.</div>`)
        formButton.disabled = true
        formButton.classList.add('disabled');
      }
    })
  }
}

export { checkSiret };
